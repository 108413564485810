import React from 'react'
import styled from 'styled-components'
import { graphql } from "gatsby"

import { Layout, Form, Seo } from '../components'
import theme from '../../config/theme'
import translate from '../../lang'

const Section = styled.main`
  width: 100vw;
  max-width: 100%;
  padding: 10px 0;
`

const Row = styled.div`
  max-width: ${theme.container};
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`

const Title = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 20px 0;
  border-top: 2px solid #444;
  
  h1 {
    font-size: 28px;
    text-align: center;

    @media (min-width: ${theme.breakpoints.tablet}) {
      text-align: left;
    }
    @media (min-width: ${theme.breakpoints.desktop}) {
      font-size: 50px;
    }
  }

  h1, h2 {
    margin: 0;
    padding: 0;
    font-weight: 500;
  }
`

export default ({data}) => (
  <Layout>
    <Seo />
    <Section>
      <Row>
        <Title>
          <h1>{ translate('index', 'title') }</h1>
        </Title>
      </Row>
      <div id="content" style={{ transition: 'all 0.5s ease-out' }}>
        <Form />
      </div>
    </Section>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        name
      }
    }
  }
`